<template>
  <v-card class="ma-10 pa-10" fluid>
    <v-card-title class="pl-0"
      >Choose a delivery option for your e-commerce site</v-card-title
    >

    <v-card elevation="0">
      <v-tabs color="deep-purple accent-4" v-model="selectedOption">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>

        <v-tab-item>
          <v-container fluid v-if="!runnarApi">
            <v-form v-model="validAPI" ref="apiForm">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Your API key"
                    v-model="api_key"
                    :rules="[(v) => !!v || 'Please provide your API key']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="URL to get all available districts"
                    v-model="area_url"
                    :rules="[(v) => !!v || 'Please provide required URL']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="URL to get all location under districts"
                    v-model="location_url"
                    :rules="[(v) => !!v || 'Please provide required URL']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="URL to get estimations for area and location"
                    v-model="estimation_url"
                    :rules="[(v) => !!v || 'Please provide required URL']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions>
              <v-btn
                color="success"
                :dark="validAPI"
                elevation="0"
                :disabled="!validAPI"
                @click="confirm('enable')"
                >Enable</v-btn
              >
            </v-card-actions>
          </v-container>
          <v-container class="text-center" v-else>
            <v-btn color="red" dark elevation="0" @click="confirm('disable')">
              disable runnar
            </v-btn>
          </v-container>
        </v-tab-item>

        <!-- <v-tab-item>
          <v-container fluid>
            <v-card flat>
              <v-row justify="center">
                <v-col>
                  <v-container>
                    <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="(district, index) in districts"
                        :key="index"
                      >
                        <v-expansion-panel-header
                          >District :
                          {{ district.district_name }}
                          <v-btn
                            v-if="district.status == false"
                            text
                            max-width="80"
                            small
                            class="ml-4"
                            @click="districtStatusChage(district, 1)"
                            >Make Active</v-btn
                          >
                          <v-btn
                            v-else
                            text
                            max-width="80"
                            small
                            class="ml-4"
                            color="red"
                            @click="districtStatusChage(district, 0)"
                            >Make Inactive</v-btn
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-expansion-panels
                            class="mb-6"
                            :disabled="district.status == false"
                          >
                            <v-expansion-panel
                              v-for="(thana, i) in district.thanas"
                              :key="i"
                              :readonly="thana.status == false"
                            >
                              <v-expansion-panel-header
                                expand-icon="mdi-menu-down"
                              >
                                Thana : {{ thana.thana_name }}
                                <v-btn
                                  v-if="thana.status == false"
                                  text
                                  max-width="80"
                                  small
                                  class="ml-4"
                                  @click="thanaStatusChage(thana, 1)"
                                  >Make Active</v-btn
                                >
                                <v-btn
                                  v-else
                                  text
                                  max-width="80"
                                  small
                                  class="ml-4"
                                  color="red"
                                  @click="thanaStatusChage(thana, 0)"
                                  >Make Inactive</v-btn
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-container>
                                  <v-row>
                                    <v-col cols="6"
                                      ><v-text-field
                                        v-model="thana.unit_price"
                                        label="Unit Price"
                                        type="number"
                                      ></v-text-field>

                                      <v-text-field
                                        v-model="thana.delivery_day"
                                        label="Delivery Day"
                                        type="number"
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="thana.minimum_amount"
                                        label="minimum Amount"
                                        type="number"
                                      ></v-text-field>

                                      <v-btn
                                        color="primary"
                                        elevation="0"
                                        width="200"
                                        class="mt-3"
                                        @click="onSaveThana(district, thana)"
                                        :disabled="!thana.status"
                                      >
                                        Save
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-container>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-tab-item> -->
        <v-tab-item>
          <v-container fluid>
            <v-card flat>
              <v-row justify="center">
                <v-col>
                  <manual-api></manual-api>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-tab-item>
        <!-- Start Add area and location tab -->
        <v-tab-item>
          <v-container class="py-14 d-flex justify-center">
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="area_dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="deep-purple darken-2" v-bind="attrs" v-on="on"
                  >Add District</v-btn
                >
              </template>
              <template>
                <v-card>
                  <v-toolbar color="deep-purple darken-2" dark
                    >New District</v-toolbar
                  >
                  <v-card-text>
                    <v-text-field
                      outlined
                      color="deep-purple darken-2"
                      placeholder="District Name.."
                      v-model="new_area.Area_name"
                    ></v-text-field>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="District Details"
                      color="deep-purple darken-2"
                      v-model="new_area.Area_details"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="area_dialog = false" color="red"
                      >Close</v-btn
                    >
                    <v-btn
                      color="deep-purple darken-2"
                      @click="onAddArea"
                      :disabled="!new_area.Area_name.trim()"
                      >Add District</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>

            <v-btn
              color="deep-purple darken-2"
              @click="onOpenLocationDialog"
              class="ml-5"
              >Add Thana</v-btn
            >
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="loaction_dialog"
            >
              <template>
                <v-card>
                  <v-toolbar color="deep-purple darken-2" dark
                    >New Thana</v-toolbar
                  >
                  <v-card-text>
                    <v-autocomplete
                      v-model="new_location.area_id"
                      :items="areas"
                      dense
                      filled
                      label="Select District"
                      item-text="Area_name"
                      item-value="id"
                      color="deep-purple darken-2"
                      outlined
                    ></v-autocomplete>
                    <v-text-field
                      outlined
                      color="deep-purple darken-2"
                      placeholder="Thana name.."
                      v-model="new_location.location_name"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="loaction_dialog = false" color="red"
                      >Close</v-btn
                    >
                    <v-btn
                      color="deep-purple darken-2"
                      @click="onAddLocation"
                      :disabled="
                        !new_location.location_name.trim() ||
                        !new_location.area_id
                      "
                      >Add Thana</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-container>
        </v-tab-item>
        <!--End Add area and location tab -->
      </v-tabs>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2 py-2">
          Policy
        </v-card-title>

        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="dialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-if="enableAPICall" @click="enableRunnar"
            >Enable</v-btn
          >
          <v-btn color="primary" text v-else @click="disableRunnar"
            >Disable</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="white" centered white>
      <p :class="[error ? 'red--text' : 'black--text']" class="mb-0">
        {{ text }}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn :color="btnColor" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>


<script>
export default {
  data: () => ({
    validAPI: false,
    selectedOption: null,
    items: [
      { tab: "Runnar API" },
      { tab: "Manual API" },
      { tab: "Add Area & Location" },
    ],
    name: "",
    details: "",
    runnarApi: false,
    districts: [],
    api_key: "",
    area_url: "",
    location_url: "",
    estimation_url: "",
    text: "",
    error: false,
    snackbar: false,
    dialog: false,
    enableAPICall: true,
    btnColor: "",

    new_area: {
      Area_name: "",
      Area_details: "",
      is_active: true,
    },
    area_dialog: false,
    loaction_dialog: false,
    areas: [],
    new_location: {
      area_id: "",
      location_name: "",
      is_active: true,
    },
  }),
  components: {
    ManualApi : ()=> import(/* */ '@/components/api/delivery/ManualApi')
  },
  methods: {
    initialize() {
      //Get available districts
      axios.get("supports/all_info").then((response) => {
        this.districts = response.data.data;
        // this.districts = [
        //   {
        //     district_name: "Gazipur",
        //     thanas: [
        //       {
        //         thana_name: "Pubail",
        //         unit_price: 23,
        //         delivery_day: 12,
        //         minimum_amount: 100,
        //       },
        //       {
        //         thana_name: "Kaligonj",
        //         unit_price: 12,
        //         delivery_day: 14,
        //         minimum_amount: 200,
        //       },
        //     ],
        //   },
        //   {
        //     district_name: "Dhaka",
        //     thanas: [
        //       {
        //         thana_name: "Mirpur",
        //         unit_price: 23,
        //         delivery_day: 12,
        //         minimum_amount: 100,
        //       },
        //       {
        //         thana_name: "Dhanmondi",
        //         unit_price: 12,
        //         delivery_day: 14,
        //         minimum_amount: 200,
        //       },
        //     ],
        //   },
        // ];
      });

      // Check enable / disable of runnnar api
      axios.get(`supports/check_enable/Runner`).then((response) => {
        if (response.data.status == "enabled") {
          this.runnarApi = true;
        }
      });
    },

    districtStatusChage(district, status) {
      axios
        .get(
          `supports/district_change_status/${district.district_name}/${status}`
        )
        .then((response) => {
          if (status) {
            district.status = true;
            this.text = "Activated successfully";
          } else {
            district.status = false;
            this.text = "Deactivated successfully";
          }
          this.btnColor = "red";
          this.snackbar = true;
        })
        .catch((err) => {
          this.text = "Something went wrong !";
          this.btnColor = "red";
          this.snackbar = true;
        });
    },

    thanaStatusChage(thana, status) {
      // console.log("Before ", thana.thana_name, status);
      axios
        .get(`supports/thana_change_status/${thana.thana_name}/${status}`)
        .then((response) => {
          if (response.data.success) {
            if (status) {
              thana.status = true;
              this.text = "Activated successfully";
            } else {
              thana.status = false;
              this.text = "Deactivated successfully";
            }
            this.btnColor = "red";
            this.snackbar = true;
          } else {
            this.text = "Something went wrong !";
            this.btnColor = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !";
          this.btnColor = "red";
          this.snackbar = true;
        });
    },

    onSaveThana(district, thana) {
      let insertedCharge = {
        specification_id: -1,
        Area_name: district.district_name,
        Area_details: "",
        location_name: thana.thana_name,
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        measument_unit: "kg",
        unit_price: thana.unit_price,
        delivery_day: thana.delivery_day,
        minimum_amount: thana.minimum_amount,
      };

      axios
        .post(`supports/addcharge/`, insertedCharge)
        .then((response) => {
          if (response.data.success) {
            this.text = `Inside city charge is added for ${thana.thana_name}`;
            this.btnColor = "pink";
            this.error = false;
          } else {
            this.text = `Inside city charge is not added for ${thana.thana_name}`;
            this.btnColor = "black";
            this.error = true;
          }
        })
        .catch((err) => {
          this.text = `Somethimg went wrong`;
          this.btnColor = "black";
          this.error = true;
        });

      this.snackbar = true;
    },

    onSaveOutside(area) {
      area.outside_city.specification_id = -1;
      area.outside_city.Area_name = area.area_name;
      area.outside_city.Area_details = "";
      area.outside_city.height = 0;
      area.outside_city.width = 0;
      area.outside_city.length = 0;
      area.outside_city.weight = 0;
      area.outside_city.measument_unit = "kg";
      area.outside_city.location_name = "Outside City";

      axios
        .post(`supports/addcharge/`, area.outside_city)
        .then((response) => {
          if (response.data.success) {
            this.text = `Outside city charge is added for ${area.area_name}`;
            this.btnColor = "pink";
            this.error = false;
          } else {
            this.text = `Outside city charge is not added for ${area.area_name}`;
            this.btnColor = "black";
            this.error = true;
          }
        })
        .catch((err) => {
          this.text = `Somethimg went wrong`;
          this.btnColor = "black";
          this.error = true;
        });
      this.snackbar = true;
    },

    confirm(confirmFor) {
      if (confirmFor == "enable") {
        this.text = "Runnar will replace your manual delivery system";
      } else {
        this.text =
          "Disabling runnar will automatically enable your manual delivery option";

        this.enableAPICall = false;
      }

      this.dialog = true;
    },

    enableRunnar() {
      //API call to enable
      // params 1 for enable
      axios
        .post(`supports/enable_disable/Runner/1`, {
          API_key: this.api_key,
          area_url: this.area_url,
          location_url: this.location_url,
          estimation_url: this.estimation_url,
        })
        .then((response) => {
          if (response.data.success) {
            this.$refs.apiForm.reset();

            this.text = `Runnar api is enabled`;
            this.btnColor = "pink";
            this.error = false;
            this.runnarApi = true;
          } else {
            this.text = `Runnar api can not be enabled.`;
            this.btnColor = "black";
            this.error = true;
          }
        })
        .catch((err) => {
          this.text = `Runnar api can not be enabled.`;
          this.btnColor = "black";
          this.error = true;
        });

      this.dialog = false;
      this.snackbar = true;
    },

    // Disable runnar api
    disableRunnar() {
      //API call to disable
      // params 0 for disable
      axios
        .post(`supports/enable_disable/Runner/0`, {})
        .then((response) => {
          if (response.data.success) {
            this.runnarApi = false;
            this.enableAPICall = true;
            this.text = `Runnar api is disabled`;
            this.btnColor = "pink";
            this.error = false;
          } else {
            this.text = `Something went wrong !`;
            this.btnColor = "black";
            this.error = true;
          }
        })
        .catch((err) => {
          this.text = `Something went wrong !`;
          this.btnColor = "black";
          this.error = true;
        });
      this.dialog = false;
      this.snackbar = true;
    },

    // Area add
    onAddArea() {
      if (this.new_area.Area_name.trim()) {
        axios
          .post(`/supports/addarea/`, this.new_area)
          .then((response) => {
            if (response.data.success) {
              this.text = `District added successfully.!`;
              this.btnColor = "pink";
              this.snackbar = true;

              this.area_dialog = false;

              this.new_area = {
                Area_name: "",
                Area_details: "",
                is_active: false,
              };
            } else {
              this.text = `Something went wrong !`;
              this.btnColor = "pink";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = `Something went wrong !`;
            this.btnColor = "pink";
            this.snackbar = true;
          });
      }
    },

    onOpenLocationDialog() {
      // make api call
      axios.get(`supports/all_areas/`).then((response) => {
        if (response.data.success) {
          this.areas = response.data.data;
          this.loaction_dialog = true;
        }
      });
    },

    onAddLocation() {
      if (this.new_location.location_name.trim() && this.new_location.area_id) {
        axios
          .post(`/supports/addlocation/`, this.new_location)
          .then((response) => {
            if (response.data.success) {
              this.text = `Thana added successfully.!`;
              this.btnColor = "pink";
              this.snackbar = true;

              this.loaction_dialog = false;

              this.new_location = {
                area_id: "",
                location_name: "",
                is_active: true,
              };
            } else {
              this.text = `Something went wrong !`;
              this.btnColor = "pink";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = `Something went wrong !`;
            this.btnColor = "pink";
            this.snackbar = true;
          });
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
